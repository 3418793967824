const patternForPhone = /^[0]?[123456789]\d{8,12}$/;
const patternForAge = /^\d{2}$/;
const patternForYearOfBirth = /^\d{4}$/;

export const isCorrectName=(name)=>{
    return name != "";
}

export const isCorrectPhone=(phone)=>{
    return patternForPhone.test(phone);
}

export const isCorrectAge=(age)=>{
    return patternForAge.test(age);
}
export const isCorrectYear=(year)=>{
    if(patternForYearOfBirth.test(year)){
        if(year>="1900" && year<= "2025"){
            return true;
        }
    }
    return false;
}