export const callSettingUserDimension = (dimension) => {
  let settingWidth = 360;
  let settingHeight = 640;
  if (settingWidth >= dimension.width - 10) {
      settingWidth = dimension.width - 10;
  }
  if (settingHeight >= dimension.height - 10) {
      settingHeight = dimension.height - 10;
  }
  if (settingWidth * 16 >= settingHeight * 9) {
      settingWidth = settingHeight * 9 / 16;
  } else {
      settingHeight = settingWidth * 16 / 9;
  }
  return { settingWidth: settingWidth, settingHeight: settingHeight };
};