import {
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from "react-native";
import {
    COLOR_BORDER,
    BORDER_WIDTH,
    DEFAULT_MARGIN,
    DEFAULT_PADDING,
    TXT_SEX_FEMALE,
    TXT_SEX_MALE,
    URL_IMAGE_FEMALE,
    URL_IMAGE_MALE,
} from "../utils/Constants";
import { Image } from "react-native";
import { useEffect, useState } from "react";
import { Pressable } from "react-native";
import { isCorrectName, isCorrectYear } from "../utils/CustomerUtils";
import { uploadSettingUser } from "../utils/SeverFuncs";
import RadioButton from "../guis/RadioButton";
import { getUrlFromStorage } from "../utils/Tool";

const callSettingUserDimension = (dimension) => {
    let settingWidth = 360;
    let settingHeight = 640;
    if (settingWidth >= dimension.width - 10) {
        settingWidth = dimension.width - 10;
    }
    if (settingHeight >= dimension.height - 10) {
        settingHeight = dimension.height - 10;
    }
    if (settingWidth * 16 >= settingHeight * 9) {
        settingWidth = (settingHeight * 9) / 16;
    } else {
        settingHeight = (settingWidth * 16) / 9;
    }
    return { settingWidth: settingWidth, settingHeight: settingHeight };
};
const BasicUserFrom = ({dimension, user, onPressUpdate, onPressAvatar})=>{
    const [displayName, setDisplayName] = useState(
        user?.displayName ? user.displayName : ""
    );
    const [yearOfBirth, setYearOfBirth] = useState(
        user?.yearOfBirth ? user.yearOfBirth : ""
    );
    const [sex, setSex] = useState(user?.sex ? user.sex : TXT_SEX_MALE);
    const [uriAvatar, setUriAvatar] = useState(user?.uriAvatar ? user.uriAvatar: '');
    const [infoText, setInfoText] = useState("Wellcome to KTab!");
    const [textColor, setTextColor] = useState("orange");
    const { settingWidth, settingHeight } = callSettingUserDimension(dimension);
    var logoSize = settingWidth / 3;
    var logoBorderRadius = logoSize / 2;
    var textInputHeight = 40;
    var submitBtnWidth = settingWidth / 2;
    var uriAvatarShow = uriAvatar;
    if(uriAvatarShow == ''){
        if(sex == TXT_SEX_MALE){
            uriAvatarShow = URL_IMAGE_MALE;
        }else{
            uriAvatarShow = URL_IMAGE_FEMALE;
        }
    }

    useEffect(()=>{
        const updateUriAvatar = async()=>{
            if(user?.sex){
                setSex(user.sex);
            }
            if(user?.yearOfBirth){
                setYearOfBirth(user.yearOfBirth);
            }
            if(user?.displayName){
                setDisplayName(user.displayName);
            }
            if(user?.storageAvatar){
                let urlStorage = await getUrlFromStorage(user.storageAvatar);
                setUriAvatar(urlStorage);
            }
        }
        updateUriAvatar();
    },[user]);
   
    const updateSetting2Sever = async () => {
        if (displayName == "" || !isCorrectName(displayName)) {
            setTextColor("red");
            setInfoText("Not correct display name!");
            return;
        }
        if (yearOfBirth == "" || !isCorrectYear(yearOfBirth)) {
            setTextColor("red");
            setInfoText("Not correct year of borth!");
            return;
        }
        let params = {};
        params["display_name"] = displayName;
        params["year_of_birth"] = yearOfBirth;
        params["sex"] = sex;
        let data = await uploadSettingUser(params);
        if (data.code == 0) {
            setTextColor("orange");
            setInfoText("Thank you!");
            if(onPressUpdate){
                onPressUpdate();
            }
        } else {
            setTextColor("red");
            setInfoText("Sorry. Network Error!");
        }
    };
    const handleUpdateSex = (value)=>{
        setSex(value);
    }
    return (
        <View
            style={{
                width: {settingWidth},
                height: {settingHeight},
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Pressable style={{ width: logoSize, height: logoSize }} onPress={() => {
                if(onPressAvatar){
                    onPressAvatar();
                }
            }}>
                <Image
                    style={{
                        width: logoSize,
                        height: logoSize,
                        resizeMode: "contain",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: logoBorderRadius,
                        backgroundColor: "white",
                        borderColor: COLOR_BORDER,
                        borderWidth: BORDER_WIDTH,
                    }}
                    source={{ uri: uriAvatarShow }}
                ></Image>
            </Pressable>

            <View
                style={{
                    height: textInputHeight,
                    width: settingWidth,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Text
                    style={{
                        fontSize: 15,
                        color: textColor,
                    }}
                    selectable={false}
                >
                    {infoText}
                </Text>
            </View>
            <View>
                <TextInput
                    style={{
                        color: "#525252",
                        textAlign: "center",
                        borderRadius: textInputHeight,
                        height: textInputHeight,
                        width: settingWidth,
                        margin: DEFAULT_MARGIN,
                        borderWidth: BORDER_WIDTH,
                        padding: DEFAULT_PADDING,
                        backgroundColor: "#A7D1F6",
                        borderColor: "#4C8EF6",
                    }}
                    onChangeText={setDisplayName}
                    value={displayName}
                    placeholder={"DISPLAY NAME"}
                />
            </View>
            <View
                style={{
                    flexDirection: "row",
                    alignItems: "stretch",
                    width: settingWidth,
                }}
            >
                <RadioButton
                    size={textInputHeight}
                    description={TXT_SEX_MALE.toUpperCase()}
                    width={settingWidth / 2}
                    height={textInputHeight}
                    value={TXT_SEX_MALE}
                    choosed={sex}
                    onPress={() => handleUpdateSex(TXT_SEX_MALE)}
                />
                <RadioButton
                    size={textInputHeight}
                    description={TXT_SEX_FEMALE.toUpperCase()}
                    width={settingWidth / 2}
                    height={textInputHeight}
                    value={TXT_SEX_FEMALE}
                    choosed={sex}
                    onPress={() => handleUpdateSex(TXT_SEX_FEMALE)}
                />
            </View>

            <View>
                <TextInput
                    style={{
                        color: "#525252",
                        textAlign: "center",
                        borderRadius: textInputHeight,
                        height: textInputHeight,
                        width: settingWidth,
                        margin: DEFAULT_MARGIN,
                        borderWidth: BORDER_WIDTH,
                        padding: DEFAULT_PADDING,
                        backgroundColor: "#A7D1F6",
                        borderColor: "#4C8EF6",
                    }}
                    onChangeText={setYearOfBirth}
                    value={yearOfBirth}
                    placeholder={"YEAR OF BIRTH"}
                    keyboardType={"phone-pad"}
                />
            </View>

            <View>
                <TouchableOpacity
                    style={{
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#538EF6",
                        padding: DEFAULT_PADDING,
                        margin: DEFAULT_MARGIN,
                        width: submitBtnWidth,
                        height: textInputHeight,
                        borderRadius: textInputHeight,
                    }}
                    onPress={updateSetting2Sever}
                >
                    <Text
                        style={{
                            color: "orange",
                            fontStyle: "bold",
                        }}
                    >
                        UPDATE
                    </Text>
                </TouchableOpacity>
            </View>
        </View>
    );
}

export default BasicUserFrom;