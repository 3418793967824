export const SET_UID = 'SET_UID';
export const SET_USER = 'SET_USER';
export const SET_SCREEN = 'SET_SCREEN';

export const setScreen = screen => dispatch =>{
    dispatch({
        type: SET_SCREEN,
        payload: screen
    });
}

export const setUid = uid => dispatch => {
    dispatch({
        type: SET_UID,
        payload: uid,
    });
};

export const setUser = user => dispatch =>{
    dispatch({
        type: SET_USER,
        payload: user,
    });    
    dispatch({
        type: SET_UID,
        payload: user._id,
    });
}
