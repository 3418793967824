import { View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { generateNaviScreensExpect, getIconForScreen} from "../utils/ScreenUtils";
import SelectDropdown from 'react-native-select-dropdown';
import { useDispatch, useSelector } from "react-redux";
import { setScreen } from "../states/actions";

const NaviComponent = ({ navigation, screenName, dimension }) => {
    const { screen } = useSelector(state => state.firebaseReducers);
    const dispatch = useDispatch();
    const gotoScreen = (nextScreen) => {
        navigation.navigate(nextScreen);
    }
    const menuIcons = generateNaviScreensExpect(screenName);
    return (
        <View style={{ position: 'absolute', top: 5, left: 5, zIndex: 2, alignItems: "flex-start", flexDirection: "column" }}>
            <SelectDropdown
                data={menuIcons}
                onSelect={(selectedItem, index) => {
                    var screenSelect = selectedItem.value;
                    dispatch(setScreen({ name: screenSelect }));
                    gotoScreen(screenSelect);
                }}
                renderButton={(selectedItem, isOpened) => {
                    return (
                        <View sytle={{
                            width: 250,
                            height: 50,
                            backgroundColor: '#E9ECEF',
                            borderRadius: 12,
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingHorizontal: 12,
                        }}>
                            <Icon
                                name={getIconForScreen(screen.name)}
                                style={{
                                    fontSize: 32,
                                    marginRight: 8,
                                }}
                                color={'green'}
                            />
                            <Icon name={isOpened ? 'chevron-up' : 'chevron-down'}
                                style={{
                                    fontSize: 32,
                                    backgroundColor: "#84B191",
                                    borderRadius: 8,
                                    color: 'green'
                                }}
                            />
                        </View>
                    );
                }}
                renderItem={(item, index, isSelected) => {
                    return (
                        <View
                            style={{
                                width: '100%',
                                flexDirection: 'row',
                                paddingHorizontal: 12,
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingVertical: 8,
                                backgroundColor: "transparent"
                            }}
                        >
                            <Icon name={item.icon}
                                style={{
                                    fontSize: 32,
                                }}
                                color={'green'}
                            />
                        </View>
                    );
                }}
                showsVerticalScrollIndicator={false}
                dropdownStyle={{
                    backgroundColor: 'transparent',
                    borderRadius: 8,
                }}
            />
        </View>
    )
}
export default NaviComponent;