import { ICON_NAME_CHAT, ICON_NAME_ECO, ICON_NAME_FORM, ICON_NAME_HOME, ICON_NAME_MUSIC } from "./Constants";

export var screens={
    home: "Home",
    music: "Music",
    eco: "Eco",
    chat: "Chat",
    form: "Form",
    settingUser: "SettingUser",
};

export const getIconForScreen =(screenName)=>{
    if (screenName == screens.home) return ICON_NAME_HOME;
    if (screenName == screens.eco) return ICON_NAME_ECO;
    if (screenName == screens.chat) return ICON_NAME_CHAT;
    if (screenName == screens.form) return ICON_NAME_FORM;
    if (screenName == screens.music) return ICON_NAME_MUSIC;
    return "";
}

export const generateNaviScreensExpect = (screenName)=>{
    let result = [];
    if (screenName != screens.home) result.push({icon: getIconForScreen(screens.home), value: screens.home});
    if (screenName != screens.eco) result.push({icon: getIconForScreen(screens.eco), value: screens.eco});
    if (screenName != screens.chat) result.push({icon: getIconForScreen(screens.chat), value: screens.chat});
    if (screenName != screens.form) result.push({icon: getIconForScreen(screens.form), value: screens.form});
    if (screenName != screens.music) result.push({icon: getIconForScreen(screens.music), value: screens.music});
    return result;
}