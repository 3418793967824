import { useEffect, useLayoutEffect, useState } from 'react';
import { Image, Text, TextInput, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { getLastSlide, getSlide, isReception, randomSlide } from '../data/manualData';
import { screens } from '../utils/ScreenUtils';
import { Audio } from "expo-av";
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { getDatabase, ref as refRT, onValue, child, off, set } from "firebase/database";
import { COLOR_RUNGCO_ICON, COLOR_SCREEN_BTN, ICON_NAME_BELL, ICON_NAME_SETTING } from '../utils/Constants';
import NaviComponent from '../components/NaviComponent';
import { useDispatch } from 'react-redux';
import { setUser } from '../states/actions';

var urlBell = require("../../assets/bell.wav");
var isBellRinging = false;
var defaultWellcomeTxt = "Wellcome to Rung Co !!!";

const SlideScreen = ({ route, navigation }) => {
  const [showLongCaption, setShowLongCaption] = useState(false);
  const [caption, setCaption] = useState("captionState");
  const [longCaption, setLongCaption] = useState("longCaptionState");
  const [next, setNext] = useState("-1");
  const [prev, setPrev] = useState("-1");
  const [sourceImg, setSourceImg] = useState(require("../../assets/background.jpg"));
  const [bellBackground, setBellBackground] = useState(COLOR_RUNGCO_ICON);
  const [wellcomeTxt, setWellcomeTxt] = useState(defaultWellcomeTxt);
  const { uid, user } = useSelector(state => state.firebaseReducers);
  const dispatch = useDispatch();

  const { dimension } = route.params;
  const actionShowInfo = async (txt) => {
    var myTimeout = setTimeout(() => {
      clearTimeout(myTimeout);
    }, 5000);
    var mySecondTimeout = setTimeout(() => {
      setWellcomeTxt(defaultWellcomeTxt);
      clearTimeout(mySecondTimeout);
    }, 10000);
    setWellcomeTxt(txt);
    const { sound } = await Audio.Sound.createAsync(urlBell);
    sound.setIsLoopingAsync(false);
    sound.playAsync();
  }

  // console.log(user);
  useLayoutEffect(() => {
    onValue(
      child(refRT(getDatabase()), "personal/" + uid),
      (snapshot) => {
        if (snapshot.exists()) {
          let personal = snapshot.val();
          let addUser = {};
          let needUpdateUser = false;
          if (personal?.avatars?.global?.storagePath) {
            addUser['storageAvatar'] = personal?.avatars?.global?.storagePath;
            if (addUser['storageAvatar'] != user.storageAvatar) {
              needUpdateUser = true;
            }
          }
          let needGotoScreen = true;
          if (personal?.setting) {
            needGotoScreen = false;
            let setting = personal.setting;
            if (setting?.displayName) {
              addUser["displayName"] = setting?.displayName;
              if (addUser['displayName'] != user.displayName) {
                needUpdateUser = true;
              }
            }
            if (setting?.sex) {
              addUser["sex"] = setting?.sex;
              if (addUser['sex'] != user.sex) {
                needUpdateUser = true;
              }
            }
            if (setting?.yearOfBirth) {
              addUser["yearOfBirth"] = setting?.yearOfBirth;
              if (addUser['yearOfBirth'] != user.yearOfBirth) {
                needUpdateUser = true;
              }
            }
          }
          if (needUpdateUser) {
            dispatch(setUser({ ...user, ...addUser }));
          }
          if (needGotoScreen) {
            gotoScreen(screens.settingUser);
          }
        } else {
          gotoScreen(screens.settingUser);
        }
      }
    );
    return () => {
      off(child(refRT(getDatabase()), "personal/" + uid));
    };
  }, [uid, user]);

  useLayoutEffect(() => {
    let receptionRef = null;
    if (isReception(user.name)) {
      receptionRef = child(refRT(getDatabase()), "reception/");
      onValue(receptionRef, (snapshot) => {
        if (snapshot.exists()) {
          let data = snapshot.val();
          if (data?.user && user.name != data.user) {
            actionShowInfo("Help : " + data.user);
            set(refRT(getDatabase(), "reception"), null);
          }
        }
      });
    }
    return () => {
      if (receptionRef != null)
        off(receptionRef);
      receptionRef = null;
    }
  }, [user]);

  function updateSlide(slide) {
    setSourceImg(slide.background);
    setCaption(slide.caption);
    setLongCaption(slide.longCaption);
    setNext(slide.next);
    setPrev(slide.prev);
  }
  const refreshSlide = () => {
    let slide = randomSlide();
    updateSlide(slide);
  }
  const prevSlide = () => {
    if (prev == "-1") {
      refreshSlide();
    }
    else {
      let slide = getSlide(prev);
      updateSlide(slide);
    }
  }
  const nextSlide = () => {
    if (next == "-1") {
      refreshSlide();
    }
    else {
      let slide = getSlide(next);
      updateSlide(slide);
    }
  }
  const lastSlide = () => {
    let slide = getLastSlide();
    updateSlide(slide);
  }
  const gotoScreen = (nameScreen) => {
    navigation.navigate(nameScreen);
  }
  const bellRung = async () => {
    if (isBellRinging) return;
    isBellRinging = true;
    var myTimeout = setTimeout(() => {
      setBellBackground(COLOR_RUNGCO_ICON);
      isBellRinging = false;
      setWellcomeTxt(defaultWellcomeTxt);
      clearTimeout(myTimeout);
    }, 5000);
    setBellBackground("orange");
    setWellcomeTxt("Wellcome " + user.name);
    const { sound } = await Audio.Sound.createAsync(urlBell);
    sound.setIsLoopingAsync(false);
    sound.playAsync();
    const db = getDatabase();
    set(refRT(db, "reception"), {
      user: user.name
    });
  }
  useEffect(() => {
    if (prev == "-1") {
      refreshSlide();
    }
    return () => {
    }
  }, [prev]);
  let styleTxt = { width: "100%", position: 'absolute', elevation: 2, flexDirection: "row", top: 5, opacity: 1.0, left: 0, right: 0, alignItems: "center", justifyContent: "center" };
  if (dimension.width < dimension.height) {
    styleTxt.top = 75;
  }

  let screenFlexDirection = "row";
  if (dimension.width < dimension.height) {
    screenFlexDirection = "column";
  }

  return (
    <View style={{ width: "100%", height: "100%", backgroundColor: "gray", justifyContent: "center", flexDirection: "column" }}>
      <View style={{ flex: 8, width: "100%", flexDirection: "column", backgroundColor: "gray" }}>
        <Image
          style={{ width: "100%", height: "100%", resizeMode: "stretch" }}
          source={sourceImg}
        >
        </Image>
      </View>
      <View style={styleTxt}>
        <Text
          style={{ fontSize: 25, color: "orange" }}
        >
          {wellcomeTxt}
        </Text>
      </View>
      {showLongCaption ?
        <>
          <View style={{ width: "100%", position: 'absolute', elevation: 2, flexDirection: "row", bottom: 0, backgroundColor: COLOR_RUNGCO_ICON, opacity: 0.45 }}>
            <TextInput
              value={longCaption}
              style={{ width: "100%", color: "white", fontSize: 21 }}
              multiline
              readOnly={true}
              numberOfLines={9}
            />
          </View>
        </>
        :
        <></>
      }
      {dimension.width < dimension.height ?
        <View style={{ flexDirection: "row", alignItems: "center", position: "absolute", right: 5, left: 5, justifyContent: "center", opacity: 0.5 }}>
          <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
            <Icon.Button
              name='chevron-left'
              backgroundColor={COLOR_RUNGCO_ICON}
              size={32}
              onPress={prevSlide}
            />
          </View>
          <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
            <Icon.Button
              name='refresh'
              backgroundColor={COLOR_RUNGCO_ICON}
              size={32}
              onPress={refreshSlide}
            />
          </View>
          <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
            <Icon.Button
              name='chevron-right'
              backgroundColor={COLOR_RUNGCO_ICON}
              size={32}
              onPress={nextSlide}
            />
          </View>
          <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
            <Icon.Button
              name='chevron-double-right'
              backgroundColor={COLOR_RUNGCO_ICON}
              size={32}
              onPress={lastSlide}
            />
          </View>
        </View>
        :
        <View style={{ flexDirection: "column", alignItems: "flex-end", position: "absolute", right: 5 }}>
          <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
            <Icon.Button
              name='chevron-left'
              backgroundColor={COLOR_RUNGCO_ICON}
              size={32}
              onPress={prevSlide}
            />
          </View>
          <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
            <Icon.Button
              name='refresh'
              backgroundColor={COLOR_RUNGCO_ICON}
              size={32}
              onPress={refreshSlide}
            />
          </View>
          <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
            <Icon.Button
              name='chevron-right'
              backgroundColor={COLOR_RUNGCO_ICON}
              size={32}
              onPress={nextSlide}
            />
          </View>
          <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
            <Icon.Button
              name='chevron-double-right'
              backgroundColor={COLOR_RUNGCO_ICON}
              size={32}
              onPress={lastSlide}
            />
          </View>
        </View>
      }

      <View style={{ position: 'absolute', top: 5, right: 5, zIndex: 2, alignItems: "flex-start", flexDirection: screenFlexDirection }}>

        {user.name != "" ?
          <>
            <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
              <Icon.Button
                name={ICON_NAME_SETTING}
                backgroundColor={COLOR_SCREEN_BTN}
                onPress={() => gotoScreen(screens.settingUser)}
                size={32}
                style={{ width: 60 }}
              ></Icon.Button>
            </View>
            <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
              <Icon.Button
                name={ICON_NAME_BELL}
                backgroundColor={bellBackground}
                size={32}
                onPress={bellRung}
              />
            </View>
          </>

          :
          <></>
        }
      </View>

      <View style={{ width: "100%", flexDirection: "column", bottom: 0 }}>
        <View style={{ width: "100%", flexDirection: "row" }}>
          <View style={{ flex: 1 }}>
            {showLongCaption ?
              <>
                <Icon.Button
                  name='chevron-up-circle'
                  size={32}
                  backgroundColor={"orange"}
                  onPress={() => setShowLongCaption(!showLongCaption)}
                >
                </Icon.Button>
              </>
              :
              <>
                <Icon.Button
                  name='chevron-down-circle'
                  size={32}
                  backgroundColor={"orange"}
                  onPress={() => setShowLongCaption(!showLongCaption)}
                >
                  {caption}
                </Icon.Button>
              </>
            }
          </View>
        </View>
      </View>

      <NaviComponent navigation={navigation} dimension={dimension} screenName={screens.home}></NaviComponent>

    </View>
  );
}

export default SlideScreen;