import {  Text, TouchableOpacity, View} from "react-native";
import { Image } from "react-native";
import { TXT_AGREE, TXT_REFUSE, TXT_YOUT_WANT_LOGOUT, URI_LOGO } from "../utils/Constants";
import { callSettingUserDimension } from "../utils/EcoUtils";

const YesNoComponent = ({ dimension, onPressYes, onPressNo, text }) => {
    const {settingWidth, settingHeight} = callSettingUserDimension(dimension);
    return (
        <View style={{ width: settingWidth, height: settingHeight, alignItems: "center", justifyContent: "center", borderRadius: 20 }}>
            <Image
                style={{ width: "50%", height: "50%", resizeMode: "contain", alignItems: "center", justifyContent: "center" }}
                source={{ uri: URI_LOGO }}
            >
            </Image>
            <Text style={{ fontSize: "20px" }}>{text? text: TXT_YOUT_WANT_LOGOUT}</Text>
            <View style={{ width: "70%", height: "20%", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <View style={{ width: "40%", height: "20%", marginHorizontal: 10 }}>
                    <TouchableOpacity style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#538EF6", padding: 10, borderRadius: 80, borderWidth: 1 }}
                        onPress={onPressYes}
                    >
                        <Text>{TXT_AGREE}</Text>
                    </TouchableOpacity>
                </View>
                <View style={{ width: "40%", height: "20%", marginHorizontal: 10 }}>
                    <TouchableOpacity style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#B1631D", padding: 10, borderRadius: 80, borderWidth: 1 }}
                        onPress={onPressNo}
                    >
                        <Text>{TXT_REFUSE}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    )
}
export default YesNoComponent;