export const ROOT_FONT =  'TimesNewRoman';

export const COLOR_BORDER = "orange";

export const COLOR_RUNGCO_ICON = "green";
export const COLOR_SCREEN_BTN = "green";
export const COLOR_TEXTINPUT = "#525252";
export const COLOR_BACKGROUND_TEXTINPUT = "#A7D1F6";
export const COLOR_BORDER_TEXTINPUT = "#4C8EF6";
export const COLOR_BACKGROUND_PRESSABLE = "#2C86E8";

export const ICON_NAME_HOME = "home";
export const ICON_NAME_MUSIC = "music";
export const ICON_NAME_ECO = "leaf-circle-outline";
export const ICON_NAME_CHAT = "chat";
export const ICON_NAME_FORM = 'form-select';
export const ICON_NAME_SETTING = 'cog';
export const ICON_NAME_BELL = 'bell';
export const ICON_NAME_ZOOM_PLUS = "magnify-plus-outline";
export const ICON_NAME_ZOOM_MINUS = "magnify-minus-outline";
export const ICON_NAME_ARROW_LEFT = "arrow-left-bold-hexagon-outline";
export const ICON_NAME_ARROW_RIGHT = "arrow-right-bold-hexagon-outline";
export const ICON_NAME_ARROW_UP = "arrow-up-bold-hexagon-outline";
export const ICON_NAME_ARROW_DOWN = "arrow-down-bold-hexagon-outline";
export const ICON_NAME_CLOSE = "close-thick";
export const ICON_NAME_CHECK = "check-bold";
export const ICON_NAME_IMAGE_PLUS = "image-plus";
export const ICON_NAME_FILE_DOWNLOAD = "file-download";
export const ICON_NAME_DONATE = "piggy-bank";
export const ICON_NAME_LOGOUT = "logout";
export const ICON_NAME_EDUCATION = "cast-education";

export const TXT_ROOM_SALE= "Bán";
export const TXT_ROOM_HIRE= "Cho thuê";
export const TXT_ROOM_HOMESTAY= "Homestay";
export const TXT_YOUT_WANT_LOGOUT = "Bạn muốn đăng xuất";
export const TXT_AGREE = "Đồng ý";
export const TXT_REFUSE = "Từ chối";
export const TXT_RESIGN = "Đăng ký";
export const TXT_ENTER_THE_PHONE = "Nhập Số Điện Thoại";
export const TXT_ENTER_CONFIRM_CODE = "Nhập Mã Xác Thực";
export const TXT_SEND_CODE = "Gửi Mã";
export const TXT_CONFIRM = "Xác Thực";
export const TXT_SEX_MALE = "male";
export const TXT_SEX_FEMALE = "female";
export const TXT_DONATE_EDUCATION = "TRI ÂN GIÁO DỤC";
export const TXT_UPDATE = "Cập nhật";
export const TXT_BUILDING = "Tòa nhà";

export const DEFAULT_MARGIN = 5;
export const DEFAULT_PADDING = 5;
export const BORDER_WIDTH = 3;

export const BACKGROUND_H = require("../../assets/sky_h.jpg");
export const BACKGROUND_W = require("../../assets/sky_w.jpg");

export const URL_IMAGE_MALE = "https://raw.githubusercontent.com/tuanlekhac/svg_machine/main/ktab/male_image.PNG";
export const URL_IMAGE_FEMALE = "https://raw.githubusercontent.com/tuanlekhac/svg_machine/main/ktab/female_image.PNG";
export const URI_LOGO = require("../../assets/logo.png");

export const NAVI_SCREEN_STATE_EXPAND = "expand";
export const NAVI_SCREEN_STATE_REDUCE = "reduce";

const BASE_URL_SVG_MACHINE = "https://raw.githubusercontent.com/tuanlekhac/svg_machine/main/";
export const URI_COUNTRY_VNM_FLAG = BASE_URL_SVG_MACHINE+"/global/countries/VNM.PNG";