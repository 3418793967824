import { useRef, useState } from "react";
import { TextInput, Text, View, Pressable } from "react-native";
import { FirebaseRecaptchaBanner, FirebaseRecaptchaVerifierModal } from "expo-firebase-recaptcha";
import { getApp } from "firebase/app";
import { firebaseConfig } from "../../config";
import { getAuth, PhoneAuthProvider, signInWithCredential } from "firebase/auth";
import { COLOR_BACKGROUND_PRESSABLE, COLOR_BACKGROUND_TEXTINPUT, COLOR_BORDER_TEXTINPUT, COLOR_TEXTINPUT, TXT_CONFIRM, TXT_ENTER_CONFIRM_CODE, TXT_ENTER_THE_PHONE, TXT_SEND_CODE, URI_COUNTRY_VNM_FLAG } from "../utils/Constants";
import { Image } from "react-native";

const LoginComponent = ({ dimension }) => {
    const recaptchaVerifier = useRef(null);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [suffixPhoneNumber, setSuffixPhoneNumber] = useState('');
    const [verificationId, setVerificationID] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [info, setInfo] = useState('');
    const countryCode = "+84";

    const sendVerificationCode = async () => {
        if (suffixPhoneNumber == "") {
            return;
        }
        try {
            let auth = getAuth(getApp());
            auth.languageCode = 'vi';
            let phoneProvider = new PhoneAuthProvider(auth);
            let verificationId = await phoneProvider.verifyPhoneNumber(
                phoneNumber,
                recaptchaVerifier.current
            );
            setVerificationID(verificationId);
        } catch (error) {
            setInfo(`Error : ${error.message}`);
            resetComponent();
        }
    };
    const updatePhoneNumber = (phone) => {
        setSuffixPhoneNumber(phone);
        if (phone == '') {
            return;
        }
        let strPhoneNumber = countryCode + phone;

        if (phone.startsWith('0')) {
            strPhoneNumber = countryCode + phone.substring(1);
        }
        //console.log(strPhoneNumber);
        setPhoneNumber(strPhoneNumber);
    }
    const verifyVerificationCode = async () => {
        if (verificationCode == '') {
            return;
        }
        try {
            const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
            await signInWithCredential(getAuth(), credential);
        } catch (error) {
            setInfo(`Error : ${error.message}`);
            resetComponent();
        }
    };
    function resetComponent() {
        setPhoneNumber('');
        setVerificationID('');
        setVerificationCode('');
    }

    var widthLogin = Math.min(dimension.width, 390) * 0.9;
    var heightLogin = dimension.height;
    let logoSize = widthLogin / 4;
    return (
        <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
            <View style={{ width: widthLogin, height: heightLogin, justifyContent: "center", alignItems: "center" }}>
                <FirebaseRecaptchaVerifierModal
                    ref={recaptchaVerifier}
                    firebaseConfig={firebaseConfig}
                />
                <Image
                    style={{ width: logoSize, height: logoSize, resizeMode: "stretch", alignItems: "center", justifyContent: "center", marginVertical:9, borderRadius: logoSize}}
                    source={{ uri: URI_COUNTRY_VNM_FLAG}}
                >
                </Image>
                <Text style={{ fontSize: 16, lineHeight: 21, fontWeight: "bold", letterSpacing: 0.25, color: "red" }}>{info}</Text>
                {!verificationId ?
                    <>
                        <TextInput
                            placeholder={TXT_ENTER_THE_PHONE}
                            autoFocus
                            returnKeyType='done'
                            autoCompleteType='tel'
                            keyboardType={'numeric'}
                            textContentType='telephoneNumber'
                            maxLength={20}
                            value={suffixPhoneNumber}
                            onChangeText={(phone) => updatePhoneNumber(phone)}
                            style={{
                                width: widthLogin * 0.8,
                                height: 50,
                                textAlign: 'center',
                                fontSize: 21,
                                fontWeight: 'bold',
                                padding: 0,
                                margin: 0,
                                borderWidth: 2,
                                borderRadius: 30,
                                color: COLOR_TEXTINPUT,
                                backgroundColor: COLOR_BACKGROUND_TEXTINPUT,
                                borderColor: COLOR_BORDER_TEXTINPUT
                            }}
                        />
                        <Pressable
                            style={{
                                width: '50%',
                                marginTop: 20,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingVertical: 12,
                                paddingHorizontal: 32,
                                borderRadius: 30,
                                elevation: 3,
                                backgroundColor: COLOR_BACKGROUND_PRESSABLE,
                            }}
                            onPress={sendVerificationCode}
                        >
                            <Text style={{ fontSize: 16, lineHeight: 21, fontWeight: "bold", letterSpacing: 0.25, color: "white" }}>
                                {TXT_SEND_CODE}
                            </Text>
                        </Pressable>
                    </>
                    : null
                }
                {verificationId ?
                    <>
                        <TextInput
                            editable={!!verificationId}
                            placeholder={TXT_ENTER_CONFIRM_CODE}
                            returnKeyType={"done"}
                            onChangeText={setVerificationCode}
                            style={{
                                textAlign: "center",
                                width: widthLogin * 0.8,
                                height: 50,
                                textAlign: 'center',
                                fontSize: 21,
                                fontWeight: 'bold',
                                padding: 0,
                                margin: 0,
                                borderWidth: 2,
                                borderRadius: 30,
                                color: COLOR_TEXTINPUT,
                                backgroundColor: COLOR_BACKGROUND_TEXTINPUT,
                                borderColor: COLOR_BORDER_TEXTINPUT
                            }}
                            maxLength={6}
                        />
                        <Pressable
                            style={{
                                width: '50%',
                                marginTop: 20,
                                alignItems: "center",
                                justifyContent: "center",
                                paddingVertical: 12,
                                paddingHorizontal: 32,
                                borderRadius: 30,
                                elevation: 3,
                                backgroundColor: COLOR_BACKGROUND_PRESSABLE,
                            }}
                            onPress={verifyVerificationCode}
                            disabled={!verificationCode}
                        >
                            <Text style={{ fontSize: 16, lineHeight: 21, fontWeight: "bold", letterSpacing: 0.25, color: "white" }}>
                                {TXT_CONFIRM}
                            </Text>
                        </Pressable>
                    </>
                    : null
                }
                <FirebaseRecaptchaBanner />
            </View>
        </View>
    );
}
export default LoginComponent;