import { Text, View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import QRCode from "react-native-qrcode-svg";
import {ICON_NAME_FORM, TXT_DONATE_EDUCATION } from "../utils/Constants";

import { Image } from "react-native";

const uriLogo = require("../../assets/logo.png");
const callSettingUserDimension = (dimension) => {
    let settingWidth = 360;
    let settingHeight = 640;
    if (settingWidth >= dimension.width - 10) {
        settingWidth = dimension.width - 10;
    }
    if (settingHeight >= dimension.height - 10) {
        settingHeight = dimension.height - 10;
    }
    if (settingWidth * 16 >= settingHeight * 9) {
        settingWidth = settingHeight * 9 / 16;
    } else {
        settingHeight = settingWidth * 16 / 9;
    }
    return { settingWidth: settingWidth, settingHeight: settingHeight };
};
const QRComponent = ({ dimension, setShowQR }) => {
  const { settingWidth, settingHeight } = callSettingUserDimension(dimension);
  var logoSize = settingWidth / 3;
  var qrSize = settingWidth * 0.75;
  var initStr = "00020101021238580010A000000727012800069704070114190333772430110208QRIBFTTA53037045405500005802VN62190815TRI AN GIAO DUC8300840063044D0C";
  return (
      <View style={{ width: dimension.width, height: dimension.height, alignItems: "center", justifyContent: "center" }}>
          <View style={{ width: settingWidth, height: settingHeight, alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
              <Image
                  style={{ width: logoSize, height: logoSize, resizeMode: "contain", alignItems: "center", justifyContent: "center" }}
                  source={{ uri: uriLogo }}
              >
              </Image>
              <View style={{ width: settingWidth, alignItems: "center" }}>
                  <Text style={{ fontSize: "20px", fontWeight: "bold" }}>
                      {TXT_DONATE_EDUCATION}
                  </Text>
              </View>
              <View style={{ width: settingWidth, height: settingHeight - logoSize, alignItems: "center", justifyContent: "center" }}>
                  <QRCode
                      size={qrSize}
                      value={initStr}
                  />
              </View>
          </View>
          <View style={{ width: 50, height: 50, bottom: 50, right: 50, position: "absolute" }}>
              <Icon.Button
                  name={ICON_NAME_FORM}
                  size={35}
                  borderRadius={50}
                  onPress={() => setShowQR(false)}
              ></Icon.Button>
          </View>
      </View>
  );
}

export default QRComponent;