import { View, Image, PixelRatio } from "react-native";
import { COLOR_BORDER, BORDER_WIDTH, COLOR_SCREEN_BTN, ICON_NAME_ARROW_DOWN, ICON_NAME_ARROW_LEFT, ICON_NAME_ARROW_RIGHT, ICON_NAME_ARROW_UP, ICON_NAME_CHECK, ICON_NAME_CLOSE, ICON_NAME_ZOOM_MINUS, ICON_NAME_ZOOM_PLUS } from "../utils/Constants";
import { useEffect, useState } from "react";
import { SaveFormat, manipulateAsync } from "expo-image-manipulator";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";

const MINIMUM_AVATAR_SIZE = 64;
const VIEW_RATE = 0.75;
const DEFAULT_AVATAR_SIZE = 512;

const AvatarPicker = ({ dimension, uriImage, imageWidth, imageHeight, onPressClose, onPressCheck }) => {
    const [uriAvatar, setUriAvatar] = useState(uriImage);
    const [avatarSize, setAvatarSize] = useState(0);
    const [avatarPosX, setAvatarPosX] = useState(0);
    const [avatarPosY, setAvatarPosY] = useState(0);
    const [showCheck, setShowCheck] = useState(true);

    let sizeAvatarView = Math.min(dimension.width, dimension.height) * VIEW_RATE;
    let opacityZoomMinus = (avatarSize < Math.min(imageWidth, imageHeight)) ? 1.0 : 0.0;
    let opacityZoomPlus = (avatarSize > MINIMUM_AVATAR_SIZE) ? 1.0 : 0.0;
    let sizeGridArrowsView = Math.min(sizeAvatarView * 0.85, 150);
    let sizeArrowView = sizeGridArrowsView / 2.3;
    let sizeArrowPadding = (sizeGridArrowsView - sizeArrowView) / 2;
    let sizeIconArrow = PixelRatio.roundToNearestPixel(sizeArrowView);

    useEffect(() => {
        const initUriAvatar = async () => {
            let avatarSizeTmp = Math.min(imageWidth, imageHeight);
            let avatarPosXTmp = (imageWidth - avatarSizeTmp) / 2;
            let avatarPosYTmp = (imageHeight - avatarSizeTmp) / 2;
            const manipResult = await manipulateAsync(uriImage, [{ crop: { height: avatarSizeTmp, width: avatarSizeTmp, originX: avatarPosXTmp, originY: avatarPosYTmp } }], { format: SaveFormat.PNG });
            setAvatarSize(avatarSizeTmp);
            setAvatarPosX(avatarPosXTmp);
            setAvatarPosY(avatarPosYTmp);
            setUriAvatar(manipResult.uri);
        }
        initUriAvatar();
    }, []);

    const zoomAvatar = async (zoomVal) => {

        let newAvatarSize = Math.min(avatarSize * zoomVal, Math.min(imageWidth, imageHeight));
        if (newAvatarSize < MINIMUM_AVATAR_SIZE) {
            newAvatarSize = MINIMUM_AVATAR_SIZE;
        }

        let delta = (avatarSize - newAvatarSize) / 2.0;
        let newAvatarPosX = avatarPosX + delta;
        let newAvatarPosY = avatarPosY + delta;

        if (newAvatarPosX < 0) newAvatarPosX = 0;
        if (newAvatarPosY < 0) newAvatarPosY = 0;
        if (newAvatarPosX + newAvatarSize > imageWidth) newAvatarPosX = imageWidth - newAvatarSize;
        if (newAvatarPosY + newAvatarSize > imageHeight) newAvatarPosY = imageHeight - newAvatarSize;

        const manipResult = await manipulateAsync(uriImage, [{ crop: { height: newAvatarSize, width: newAvatarSize, originX: newAvatarPosX, originY: newAvatarPosY } }], { format: SaveFormat.PNG });

        setAvatarSize(newAvatarSize);
        setAvatarPosX(newAvatarPosX);
        setAvatarPosY(newAvatarPosY);

        setUriAvatar(manipResult.uri);
    }

    let moveParameter = 9;
    const moveLeft = async () => {
        let distance = avatarSize / moveParameter;
        if (avatarPosX - distance < 0) {
            return;
        }
        let newAvatarPosX = avatarPosX - distance;
        const manipResult = await manipulateAsync(uriImage, [{ crop: { height: avatarSize, width: avatarSize, originX: newAvatarPosX, originY: avatarPosY } }], { format: SaveFormat.PNG });
        setAvatarPosX(newAvatarPosX);
        setUriAvatar(manipResult.uri);
    }
    const moveRight = async () => {
        let distance = avatarSize / moveParameter;
        if (avatarPosX + distance + avatarSize > imageWidth) {
            return;
        }
        let newAvatarPosX = avatarPosX + distance;
        const manipResult = await manipulateAsync(uriImage, [{ crop: { height: avatarSize, width: avatarSize, originX: newAvatarPosX, originY: avatarPosY } }], { format: SaveFormat.PNG });
        setAvatarPosX(newAvatarPosX);
        setUriAvatar(manipResult.uri);
    }
    const moveUp = async () => {
        let distance = avatarSize / moveParameter;
        if (avatarPosY - distance < 0) {
            return;
        }
        let newAvatarPosY = avatarPosY - distance;
        const manipResult = await manipulateAsync(uriImage, [{ crop: { height: avatarSize, width: avatarSize, originX: avatarPosX, originY: newAvatarPosY } }], { format: SaveFormat.PNG });
        setAvatarPosY(newAvatarPosY);
        setUriAvatar(manipResult.uri);
    }
    const moveDown = async () => {
        let distance = avatarSize / moveParameter;
        if (avatarPosY + distance + avatarSize > imageHeight) {
            return;
        }
        let newAvatarPosY = avatarPosY + distance;
        const manipResult = await manipulateAsync(uriImage, [{ crop: { height: avatarSize, width: avatarSize, originX: avatarPosX, originY: newAvatarPosY } }], { format: SaveFormat.PNG });
        setAvatarPosY(newAvatarPosY);
        setUriAvatar(manipResult.uri);
    }

    const handleOnPressClose = () => {
        if (onPressClose) {
            onPressClose();
        }
    }

    const handleOnPressCheck = async () => {
        setShowCheck(false);
        const manipResult = await manipulateAsync(uriAvatar, [{ resize: { height: DEFAULT_AVATAR_SIZE, width: DEFAULT_AVATAR_SIZE } }], { format: SaveFormat.PNG });
        if (onPressCheck) {
            onPressCheck(manipResult.uri);
        }
        setShowCheck(true);
    }
    return (
        <View style={{ width: dimension.width, height: dimension.height, justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <View
                style={{ width: sizeAvatarView, height: sizeAvatarView, alignItems: "center", justifyContent: "center", flexDirection: "column" }}
            >
                <View style={{ width: sizeAvatarView, height: sizeAvatarView, position: "absolute", top: 0, left: 0 }}>
                    <Image
                        style={{ width: sizeAvatarView, height: sizeAvatarView, borderRadius: sizeAvatarView / 2, borderColor: COLOR_BORDER, borderWidth: BORDER_WIDTH }}
                        source={{ uri: uriAvatar }}
                    />
                </View>
                <View style={{ width: sizeGridArrowsView, height: sizeGridArrowsView, opacity: 0.85 }}>
                    <View style={{ width: sizeArrowView, height: sizeArrowView, position: "absolute", left: 0, top: sizeArrowPadding, padding: 0, margin: 0 }}>
                        <Icon.Button
                            name={ICON_NAME_ARROW_LEFT}
                            color={"orange"}
                            backgroundColor={"transparent"}
                            size={sizeIconArrow}
                            borderRadius={sizeIconArrow}
                            iconStyle={{
                                padding: 0,
                                margin: 0
                            }}
                            style={{ padding: 0 }}
                            underlayColor={COLOR_SCREEN_BTN}
                            onPress={moveLeft}
                        ></Icon.Button>
                    </View>
                    <View style={{ width: sizeArrowView, height: sizeArrowView, position: "absolute", right: 0, top: sizeArrowPadding, padding: 0, margin: 0 }}>
                        <Icon.Button
                            name={ICON_NAME_ARROW_RIGHT}
                            color={"orange"}
                            backgroundColor={"transparent"}
                            size={sizeIconArrow}
                            borderRadius={sizeIconArrow}
                            iconStyle={{
                                padding: 0,
                                margin: 0
                            }}
                            style={{ padding: 0 }}
                            underlayColor={COLOR_SCREEN_BTN}
                            onPress={moveRight}
                        ></Icon.Button>
                    </View>
                    <View style={{ width: sizeArrowView, height: sizeArrowView, position: "absolute", right: sizeArrowPadding, top: 0, padding: 0, margin: 0 }}>
                        <Icon.Button
                            name={ICON_NAME_ARROW_UP}
                            color={"orange"}
                            backgroundColor={"transparent"}
                            size={sizeIconArrow}
                            borderRadius={sizeIconArrow}
                            iconStyle={{
                                padding: 0,
                                margin: 0
                            }}
                            style={{ padding: 0 }}
                            underlayColor={COLOR_SCREEN_BTN}
                            onPress={moveUp}
                        ></Icon.Button>
                    </View>
                    <View style={{ width: sizeArrowView, height: sizeArrowView, position: "absolute", right: sizeArrowPadding, bottom: 0, padding: 0, margin: 0 }}>
                        <Icon.Button
                            name={ICON_NAME_ARROW_DOWN}
                            color={"orange"}
                            backgroundColor={"transparent"}
                            size={sizeIconArrow}
                            borderRadius={sizeIconArrow}
                            iconStyle={{
                                padding: 0,
                                margin: 0
                            }}
                            style={{ padding: 0 }}
                            underlayColor={COLOR_SCREEN_BTN}
                            onPress={moveDown}
                        ></Icon.Button>
                    </View>
                </View>
            </View>
            <View style={{ position: "absolute", bottom: 5, right: 5, flexDirection: "column-reverse" }}>

                <View style={{ marginVertical: 5, marginHorizontal: 5, opacity: opacityZoomPlus }}>
                    <Icon.Button
                        name={ICON_NAME_ZOOM_PLUS}
                        backgroundColor={COLOR_SCREEN_BTN}
                        onPress={() => zoomAvatar(0.85)}
                        size={32}
                        iconStyle={{
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginRight: 0
                        }}
                    ></Icon.Button>
                </View>

                <View style={{ marginVertical: 5, marginHorizontal: 5, opacity: opacityZoomMinus }}>
                    <Icon.Button
                        name={ICON_NAME_ZOOM_MINUS}
                        backgroundColor={COLOR_SCREEN_BTN}
                        onPress={() => zoomAvatar(1 / 0.85)}
                        size={32}
                        iconStyle={{
                            marginTop: 0,
                            marginBottom: 0,
                            paddingTop: 0,
                            paddingBottom: 0,
                            marginRight: 0
                        }}
                    ></Icon.Button>
                </View>
            </View>
            {showCheck ?
                <View style={{ position: "absolute", bottom: 5, left: 5, flexDirection: "column-reverse" }}>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name={ICON_NAME_CLOSE}
                            backgroundColor={COLOR_SCREEN_BTN}
                            color={"red"}
                            onPress={handleOnPressClose}
                            size={32}
                            iconStyle={{
                                marginTop: 0,
                                marginBottom: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginRight: 0
                            }}
                        ></Icon.Button>
                    </View>
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name={ICON_NAME_CHECK}
                            backgroundColor={COLOR_SCREEN_BTN}
                            onPress={handleOnPressCheck}
                            color={"blue"}
                            size={32}
                            iconStyle={{
                                marginTop: 0,
                                marginBottom: 0,
                                paddingTop: 0,
                                paddingBottom: 0,
                                marginRight: 0
                            }}
                        ></Icon.Button>
                    </View>
                </View>
                :
                <></>
            }
        </View>
    );
}

export default AvatarPicker;