import { View } from "react-native";
import { screens } from "../utils/ScreenUtils";
import { useState } from "react";
import { BACKGROUND_H, BACKGROUND_W } from "../utils/Constants";
import NaviComponent from "../components/NaviComponent";
import { useSelector } from "react-redux";
import LoginComponent from "../components/LoginComponent";
import { sendRoomForRent } from "../utils/SeverFuncs";
import FormComponent from "../components/FormComponent";
import { Image } from "react-native";
import QRComponent from "../components/QRComponent";

const FormScreen = ({ navigation, route }) => {
    const { uid, user } = useSelector(state => state.firebaseReducers);
    const { params } = route;
    const { dimension } = params;
    const [showQR, setShowQR] = useState(false);
    const background = { uri: dimension.width < dimension.height ? BACKGROUND_H : BACKGROUND_W };
    const gotoScreen = (nameScreen) => {
        navigation.navigate(nameScreen);
    }
    const handleOnPressSubmit = async (form) => {
        let data = await sendRoomForRent(form);
        //console.log(data);
        if (data.code == 0) {
            gotoScreen(screens.home);
        }
    }

    return (
        <View style={{ width: dimension.width, height: dimension.height, alignItems: "center", justifyContent: "center" }}>
            <Image
                style={{ width: "100%", height: "100%", resizeMode: "stretch", position: "absolute" }}
                source={background}
            ></Image>
            {uid == "" ?
                <LoginComponent dimension={dimension}></LoginComponent>
                :
                <>
                    {showQR ?
                        <QRComponent dimension={dimension} setShowQR={setShowQR}></QRComponent>
                        :
                        <FormComponent dimension={dimension} setShowQR={setShowQR} user={user} onPressSubmit={handleOnPressSubmit}></FormComponent>
                    }
                </>
            }

            <NaviComponent navigation={navigation} dimension={dimension} screenName={screens.form}></NaviComponent>
        </View>
    );
}
export default FormScreen;