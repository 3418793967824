var data1 = {"background": require("../../assets/background.jpg"), "caption": "Rừng Cọ - Tết 2024", "longCaption": "Chúc mừng năm mới", "next": "2", "prev": "2"};
var data2 = {"background": require("../../assets/background2.jpg"), "caption": "Rừng Cọ - Tết 2024", "longCaption": "Chúc mừng năm mới", "next": "1", "prev": "1"};

var datas = {"1" : data1, "2" : data2};

var residents = [];
var receptions = ["84918059763"];

export function isReception(user){
    return receptions.includes(user);
}

export function randomSlide() {
    let id = Math.floor(Math.random() * 2) + 1;
    return datas[id];
}

export function getSlide(id) {
    return datas[id];
}

export function getLastSlide() {
    return datas["2"];
}
var img01 = require("../../assets/01.jpg");
var img02 = require("../../assets/02.jpg");
var img03 = require("../../assets/03.jpg");
var img04 = require("../../assets/04.jpg");
var img05 = require("../../assets/05.jpg");
var img06 = require("../../assets/06.jpg");

// index: trong mang co n phan tu: n = imgs.length thi cac chi so duoc danh so tu 0, 1, 2, ...., n - 1
var imgs = [img01, img02, img03, img04];

var mainImgs = [img01, img02, img03, img04];

var widthMainImgs = [img01, img02, img03];
var heightMainImgs = [img04, img05, img06];

export const getImgUriMain=(imdId, dimension)=>{
    if(dimension.width > dimension.height){
        return widthMainImgs[imdId % mainImgs.length];
    }else{
        return heightMainImgs[imdId % mainImgs.length];
    }
}

export function getProductImg(id){
    let realId = (id % productImgs.length + productImgs.length) % productImgs.length;
    return productImgs[realId];
}
export function getNextImgId(id){
    id = id + 1;
    if(id >= productImgs.length){
        id = 0;
    }
    return id;
}

export function getPrevImgId(id){
    id = id - 1;
    if(id< 0){
        id = productImgs.length - 1;
    }
    return id;
}

export function getStartImgId4Product(productId){
    if(productId == 0) return 0;
    return 3;
}

// Math la thu vien co san
export function randomImage(){
    var numberImage = imgs.length;
    let id = Math.floor(Math.random() * numberImage);
    return imgs[id % numberImage];
}

var productImg0101 = require("../../assets/07.jpg");
var productImg0102 = require("../../assets/08.jpg");
var productImg0103 = require("../../assets/09.jpg");
var productImg0201 = require("../../assets/01.jpg");
var productImg0202 = require("../../assets/02.jpg");
var productImg0203 = require("../../assets/03.jpg");

var productImgs = [productImg0101, productImg0102, productImg0103, productImg0201, productImg0202, productImg0203];

var info00 = {title: "VỊ TRÍ", description: "Nằm gần khu biệt thự Vườn Tùng, Vườn Mai, khu căn hộ Rừng Cọ Ecopark được hưởng trọn vẹn cảnh quan hoàn hảo của công viên cây xanh cùng những tiện ích hiện đại của cả thành phố. Ngoài ra, khu Phố Trúc đã hoàn thiện và đi vào hoạt động kinh doanh sầm uất đáp ứng các nhu cầu mua sắm, ẩm thực giải trí… của các cư dân trong khu Rừng Cọ.", id: "00"};
var info01 = {title: "QUY MÔ", description: "Khu chung cư Rừng Cọ Ecopark được thiết kế gồm 5 phân khu  chính A, B, C, D, E với 13 tòa căn hộ cao từ  9-25 tầng đã hoàn thiện cùng nhiều tiện ích toàn diện, tạo nguồn cung cho thị trường 1.500 căn với nhiều lựa chọn đa dạng về diện tích và nhu cầu sử dụng.", id: "01"};
var info02 = {title: "THIẾT KẾ", description: "Các khối chung cư Ecopark  Rừng Cọ được thiết kế tổng hòa của sự thiết kế thông minh, không gian cảnh quan mở và hệ thống dịch vụ, tiện ích toàn diện.", id: "02"};
var info03 = {title: "KHÔNG GIAN SỐNG", description: "Bố trí thông minh, tối ưu hóa diện tích sử dụng", id: "03"};
var info04 = {title: "TIỆN ÍCH", description: "Ngoài các tiện ích vượt trội: Y tế – Giáo dục – Vui chơi giải trí… được thừa hưởng trong khu đô thị Ecopark, chung cư Rừng Cọ Ecopark còn có rất nhiều tiện ích ngay tại khu căn hộ dành cho cư dân.", id: "04"};

var infos = [info00, info01, info02, info03, info04];

export const getInfos=()=>{
    return infos;
}

export const getSubInfos=(idInfo, numInfo)=>{
    let arr = [];
    for(let i = 0; i < numInfo; i++){
        let id = (idInfo + i) % infos.length;
        arr.push(infos[id]);
    }
    return arr;
}

export const getNextIdInfo = (idInfo)=>{
    return (idInfo + 1) % infos.length;
}

export const getPrevIdInfo = (idInfo)=>{
    return (idInfo - 1 + infos.length) % infos.length;
}

var rungcoBuildings = [
    { key: '1', value: 'A1' },
    { key: '2', value: 'A2' },
    { key: '3', value: 'A3' },
    { key: '4', value: 'B1' },
    { key: '5', value: 'B2' },
    { key: '6', value: 'B3' },
    { key: '7', value: 'C1' },
    { key: '8', value: 'C2' },
    { key: '9', value: 'C3' },
    { key: '10', value: 'D1' },
    { key: '11', value: 'D2' },
    { key: '12', value: 'D3' },
    { key: '13', value: 'E1' },
    { key: '14', value: 'E2' },
    { key: '15', value: 'E3' },
];

export const getOptionForBuilding =(building)=>{
    if(building != undefined){
        for(let id = 0; id < rungcoBuildings.length; id++){
            let option = rungcoBuildings[id];
            if(option.value == building){
                return option;
            }
        }
    }
    return { key: '3', value: 'A3' };
}

export const rungcoBuildingSave = "value";

export const getRungCoBuildings=()=>{
    return rungcoBuildings;
}

export const getDefaultRungCoBuildingSelected=()=>{
    return "A3";
}

