import { getAuth } from "firebase/auth";
import { getDatabase, ref, onValue, child, off } from "firebase/database";
import axios from "axios";

var chatServer = '';

export const getChatServer = () => {
    return chatServer;
}

var firstOnValueCharSever = true;
export const listenerChatServer = () => {
    if (firstOnValueCharSever) {
        firstOnValueCharSever = false;
        onValue(child(ref(getDatabase()), "server"), (snapshot) => {
            if (snapshot.exists()) {
                chatServer = snapshot.val();
            }
        });
    }
}

export const offListenerChatServer = () => {
    if (!firstOnValueCharSever) {
        off(child(ref(getDatabase()), "server"));
        firstOnValueCharSever = true;
    }
}

export const requestSendMessage = async (group, message) => {
    let idToken = await getAuth().currentUser.getIdToken();
    let apiKey = await getAuth().currentUser.toJSON()['apiKey'];
    await axios({
        method: 'post',
        url: chatServer,
        data: {
            key: 'send',
            group: group,
            msg: message
        },
        headers: {
            Authorization: 'Bearer ' + idToken + ' ' + apiKey,
            'Access-Control-Allow-Origin': '*'
        },
        withCredentials: true
    });
    return true;
}

export const uploadSettingUser = async(params)=>{
    let idToken = await getAuth().currentUser.getIdToken();
    let apiKey = await getAuth().currentUser.toJSON()['apiKey'];
    params['key'] = 'setting_user';
    let response = await axios({
        method: 'post',
        url: chatServer,
        data: params,
        headers: {
            Authorization: 'Bearer ' + idToken + ' ' + apiKey,
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true
    });
    let data = response.data;
    return data;
}

export const uploadUriAvatar = async (uriAvatar) => {
    let idToken = await getAuth().currentUser.getIdToken();
    let apiKey = await getAuth().currentUser.toJSON()['apiKey'];
    let params = {};
    params['uri_avatar'] = uriAvatar;
    params['key'] = 'avatar';
    let response = await axios({
        method: 'post',
        url: chatServer,
        data: params,
        headers: {
            Authorization: 'Bearer ' + idToken + ' ' + apiKey,
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true
    });
    let data = response.data;
    return data;
}

export const sendRoomForRent = async(param)=>{
    let idToken = await getAuth().currentUser.getIdToken();
    let apiKey = await getAuth().currentUser.toJSON()['apiKey'];
    let params = {};
    params['form'] = param;
    params['key'] = 'room4rent';
    let response = await axios({
        method: 'post',
        url: chatServer,
        data: params,
        headers: {
            Authorization: 'Bearer ' + idToken + ' ' + apiKey,
            'Access-Control-Allow-Origin': '*',
        },
        withCredentials: true
    });
    let data = response.data;
    return data;
}