import { View } from "react-native";
import { useSelector } from "react-redux";
import { BACKGROUND_H, BACKGROUND_W } from '../utils/Constants';
import { Image } from "react-native";
import { screens } from "../utils/ScreenUtils";
import NaviComponent from "../components/NaviComponent";
import LoginComponent from "../components/LoginComponent";
import ChatComponent from "../components/ChatComponent";

const ChatScreen = ({ navigation, route }) => {
    const { uid } = useSelector(state => state.firebaseReducers);
    const { params } = route;
    const { dimension } = params;
    const background = { uri: dimension.width < dimension.height ? BACKGROUND_H : BACKGROUND_W };
    return (
        <View style={{ width: "100%", height: "100%", backgroundColor: "white", justifyContent: "center", alignItems: "center" }}>
            <Image
                style={{ width: "100%", height: "100%", resizeMode: "stretch", position: "absolute" }}
                source={background}
            ></Image>
            {uid == "" ?
                <LoginComponent dimension={dimension}></LoginComponent>
                :
                <ChatComponent dimension={dimension} ></ChatComponent>
            }
            <NaviComponent navigation={navigation} dimension={dimension} screenName={screens.chat}></NaviComponent>
        </View>
    );
}
export default ChatScreen;