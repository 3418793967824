import {
    View,
} from "react-native";
import {
    COLOR_SCREEN_BTN,
    ICON_NAME_HOME,
} from "../utils/Constants";
import Icon from "react-native-vector-icons/MaterialCommunityIcons";
import * as ImagePicker from 'expo-image-picker';
import { useState } from "react";
import { screens } from "../utils/ScreenUtils";
import { useSelector } from "react-redux";
import BasicUserFrom from "../components/BasicUserForm";
import AvatarPicker from "../components/AvatarPicker";
import { uploadUriAvatar } from "../utils/SeverFuncs";
import { getUrlFromStorage } from "../utils/Tool";


const SettingUserScreen = ({ navigation, route }) => {
    const { user } = useSelector((state) => state.firebaseReducers);  
    const { params } = route;
    const { dimension } = params;
    const [showAvatarPicker, setShowAvatarPicker] = useState(false);
    const [uriImage, setUriImage] = useState(null);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const gotoScreen = (nameScreen) => {
        navigation.navigate(nameScreen);
    };
    const waitGoToSlideScreen = async () => {
        setTimeout(() => {
            gotoScreen(screens.home);
        }, 1000);
    };
    const onPressAvatar=async()=>{
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            // aspect: [4, 3], // aspect doesn't work on IOS because its always square.
            quality: 1,
        });

        // console.log(result);

        if (!result.canceled) {
            let imageData = result.assets[0];
            setUriImage(imageData.uri);
            setImageWidth(imageData.width);
            setImageHeight(imageData.height);
            setShowAvatarPicker(true);
        }
    }
    const onPressClose =()=>{
        setShowAvatarPicker(false);
    }
    const onPressCheck = async(uriAvatar)=>{
        let data = await uploadUriAvatar(uriAvatar);
        // console.log(data)
        if(data.code == 0){
            let avatarStorage = data['avatar_storage_path'];
            let url = await getUrlFromStorage(avatarStorage);
            setUriImage(url);
            setShowAvatarPicker(false);
        }
    }
    return (
        <View
            style={{
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            {!showAvatarPicker?
                <BasicUserFrom dimension={dimension} user={user} onPressUpdate={waitGoToSlideScreen} onPressAvatar={onPressAvatar}/>
                :
                <AvatarPicker dimension={dimension} uriImage={uriImage} imageWidth={imageWidth} imageHeight={imageHeight} onPressClose={onPressClose} onPressCheck={onPressCheck}/>
            }

            {user?.displayName ? (
                <View
                    style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        flexDirection: "row",
                    }}
                >
                    <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
                        <Icon.Button
                            name={ICON_NAME_HOME}
                            backgroundColor={COLOR_SCREEN_BTN}
                            onPress={() => gotoScreen(screens.home)}
                            size={32}
                            style={{ width: 60 }}
                        ></Icon.Button>
                    </View>
                </View>
            ) : (
                <></>
            )}
        </View>
    );
};
export default SettingUserScreen;
