import {
    PixelRatio,
    Text,
    View,
} from "react-native";
import {
    BORDER_WIDTH,
    DEFAULT_PADDING,
} from "../utils/Constants";
import { Pressable } from "react-native";


// https://github.com/ThakurBallary/react-native-radio-buttons-group/blob/main/lib/RadioButton.tsx
const RadioButton = ({
    description,
    value,
    choosed,
    onPress,
    size = 24,
    width = 100,
    height = 40,
}) => {
    //console.log("size: " + size);
    const sizeFull = PixelRatio.roundToNearestPixel(size);
    const sizeHalf = PixelRatio.roundToNearestPixel(size * 0.75);
    const handlePress = () => {
        if (onPress) {
            onPress();
        }
    };
    var selected = value == choosed;
    var descriptionPaddingLeft = DEFAULT_PADDING * 2;
    return (
        <View
            style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                width: width,
            }}
        >
            <View>
                <Pressable
                    onPress={handlePress}
                    style={[
                        {
                            alignItems: "center",
                        },
                        {
                            opacity: selected ? 1.0 : 0.25,
                            width: sizeFull,
                        },
                    ]}
                >
                    <View
                        style={{
                            width: sizeFull,
                            height: sizeFull,
                            borderRadius: sizeFull,
                            borderWidth: BORDER_WIDTH,
                            borderColor: "#4C8EF6",
                            backgroundColor: "#A7D1F6",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        {value == choosed ? (
                            <View
                                style={{
                                    backgroundColor: "#4C8EF6",
                                    width: sizeHalf,
                                    height: sizeHalf,
                                    borderRadius: sizeHalf,
                                }}
                            ></View>
                        ) : (
                            <></>
                        )}
                    </View>
                </Pressable>
            </View>

            <View
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    height: { height },
                }}
            >
                <Text
                    style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "orange",
                        paddingLeft: descriptionPaddingLeft,
                    }}
                    selectable={false}
                >
                    {description}
                </Text>
            </View>
        </View>
    );
};

export default RadioButton;
