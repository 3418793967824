import { useLayoutEffect, useState } from "react";
import { Text, TouchableOpacity, View, Linking } from "react-native";
import { useSelector } from "react-redux";
import { COLOR_SCREEN_BTN, ICON_NAME_EDUCATION, ICON_NAME_FILE_DOWNLOAD, ICON_NAME_LOGOUT, TXT_SEX_FEMALE, TXT_SEX_MALE, URL_IMAGE_FEMALE, URL_IMAGE_MALE } from '../utils/Constants';
import { getAuth, signOut } from "firebase/auth";
import { Actions, GiftedChat, InputToolbar, Bubble, Send, Avatar } from "react-native-gifted-chat";
import { getDatabase, ref as refRT, onValue, child, off } from "firebase/database";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { requestSendMessage } from "../utils/SeverFuncs";
import { Image } from "react-native";
import { getColorFromUri, getUrlFromStorage } from "../utils/Tool";
import YesNoComponent from "./YesNoComponent";

var cacheAvatar = {}
const ChatComponent = ({ dimension }) => {
  const { user } = useSelector(state => state.firebaseReducers);
  const [messages, setMessages] = useState([]);
  const groupChat = "0";
  const [showYesNo, setShowYesNo] = useState(false);
  useLayoutEffect(() => {
    // console.log("useLayoutEffect");
    let groupChatRef = child(refRT(getDatabase()), "chats/" + groupChat);
    onValue(groupChatRef, async (snapshot) => {
      if (snapshot.exists()) {
        let data = snapshot.val();
        let msgs = [];
        let nCounter = 0;
        for (let index = data.index; index >= 0; index--) {
          if (data[String(index)]) {
            msgs.push(data[String(index)]);
            nCounter = 0;
          } else {
            nCounter = nCounter + 1;
            if (nCounter > 25) {
              break;
            }
          }
        }
        if (msgs.length != 0) {
          for (let index = 0; index < msgs.length; index++) {
            if (msgs[index]?.user?.storageAvatar) {
              let storageAvatar = msgs[index]?.user?.storageAvatar;
              if (cacheAvatar[storageAvatar] == undefined) {
                // console.log("getUrlFromStorage: " + storageAvatar);                                
                let urlAvatar = await getUrlFromStorage(storageAvatar);
                cacheAvatar[storageAvatar] = urlAvatar;
              }
            }
          }
        }
        setMessages(msgs);
      } else {
        setMessages([]);
      }
    });
    return () => {
      off(groupChatRef);
    }
  }, []);
  const logOut = () => {
    signOut(getAuth());
  }
  const renderBubble = (props) => {
    const { currentMessage } = props;
    if (currentMessage.downloadURL) {
      return (
        <TouchableOpacity
          onPress={() => Linking.openURL(currentMessage.downloadURL)}
        >
          <Text>
            <Icon name={ICON_NAME_FILE_DOWNLOAD} size={20} />
            {currentMessage.text}
          </Text>
        </TouchableOpacity>
      );
    }
    return <Bubble {...props} />;
  };

  const renderSend = (props) => {
    return (
      <Send {...props}
        containerStyle={{ borderWidth: 0, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 5 }}
      >
        <Icon
          name="send"
          size={29}
          color={COLOR_SCREEN_BTN}
        />
      </Send>
    );
  }

  const inputChatSetup = (props) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          marginLeft: 5,
          marginRight: 5,
          marginBottom: 5,
          borderWidth: 0.5,
          borderColor: 'grey',
          borderRadius: 15
        }}
        textInputProps={{
          ...props.textInputProps,
          blurOnSubmit: true,
          onSubmitEditing: () => {
            if (props.text && props.onSend) {
              props.onSend({ text: props.text.trim() }, true);
            }
          }
        }}
      />
    );
  };

  const renderCustomAvatar = (props) => {

    const generateShortName = (name) => {
      if (name == undefined || name.length == 0) {
        return "";
      }
      name = name.trim();
      var firstName = "";
      var middleName = "";
      var lastName = "";
      for (let i = 0; i < name.length; i++) {
        let char = name.charAt(i);
        if (char == ' ' || char == '\t' || char == '\n') {
          if (middleName.length > 0) {
            firstName = firstName + middleName.charAt(0).toString().toUpperCase();
          }
          middleName = lastName;
          lastName = "";
        } else {
          lastName = lastName + char;
        }
      }
      if (lastName.length > 0) {
        if (middleName.length > 0) {
          firstName = firstName + middleName.charAt(0).toString().toUpperCase();
        }
        middleName = lastName;
        lastName = "";
      }
      var shortName = firstName + " " + middleName;
      if (firstName.length == 0) {
        shortName = middleName;
      }
      return shortName;
    }

    const { currentMessage } = props;
    const { user } = currentMessage;
    let uri = "";
    if (user?.sex) {
      if (user?.sex == TXT_SEX_MALE) {
        uri = URL_IMAGE_MALE;
      }
      if (user?.sex == TXT_SEX_FEMALE) {
        uri = URL_IMAGE_FEMALE;
      }
    }
    if (user?.storageAvatar) {
      let storageAvatar = user?.storageAvatar;
      if (cacheAvatar[storageAvatar] != undefined) {
        uri = cacheAvatar[storageAvatar];
      }
    }
    let shortName = generateShortName(user?.displayName);
    let color = getColorFromUri(shortName);
    if (uri != "" && uri.length > 0) {
      return (
        <View style={{ alignItems: "center" }}>
          {user?.displayName ?
            <>
              <Text
                style={{
                  color: color,
                  fontStyle: "bold"
                }}
              >
                {shortName}
              </Text>
            </>
            :
            <></>
          }
          <Image
            style={{
              width: 40,
              height: 40,
              resizeMode: "contain",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 40,
              backgroundColor: "white",
              borderColor: color,
              borderWidth: 2
            }}
            source={{ uri: uri }}
          ></Image>
          {user?.yearOfBirth ?
            <>
              <Text
                style={{
                  color: color,
                }}
              >
                {user?.yearOfBirth}
              </Text>
            </>
            :
            <></>
          }
        </View>
      );
    }
    return <Avatar {...props}></Avatar>
  }

  const renderActions = (props) => {
    return (
      <Actions
        {...props}
        options={{
          // ['Send Image']: () => imagePicker(props),
          // ['Send File']: () => sendFile(props),
          // ['Cancel']: () => { console.log("Cancel") }
        }}
        icon={() => (
          <Icon name='attachment' size={25} />
        )}
      />
    );
  };

  const sendMsg = async (msg) => {
    await requestSendMessage(groupChat, msg);
  };

  const onSendMessages = (messages = []) => {
    setMessages(previousMessages => GiftedChat.append(previousMessages, messages));
    sendMsg(messages[0]);
  };

  const gotoKTab = () => {
    Linking.openURL("https://ktab-extension.web.app/");
  }

  return (
    <View style={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      {showYesNo == true ?
        <YesNoComponent dimension={dimension} onPressYes={logOut} onPressNo={() => setShowYesNo(false)} text={"Thông báo"}></YesNoComponent>
        :
        <>
          <View style={{ height: "100%", width: dimension.width }}>
            <GiftedChat
              messages={messages}
              showAvatarForEveryMessage={true}
              onSend={messages => onSendMessages(messages)}
              user={user}
              isKeyboardInternallyHandled={true}
              renderInputToolbar={props => inputChatSetup(props)}
              // renderActions={props => renderActions(props)}
              renderBubble={renderBubble}
              renderSend={renderSend}
              renderAvatar={renderCustomAvatar}
            >
            </GiftedChat>
          </View>

          <View style={{ flexDirection: "row-reverse", position: "absolute", top: 5, right: 5, zIndex: 2, alignItems: "flex-start" }}>
            <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
              <Icon.Button
                name={ICON_NAME_LOGOUT}
                backgroundColor={COLOR_SCREEN_BTN}
                onPress={() => setShowYesNo(true)}
                size={32}
                borderRadius={32}
                iconStyle={{
                  margin: 0,
                  padding: 0
                }}
              />
            </View>
            <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
              <Icon.Button
                name={ICON_NAME_EDUCATION}
                backgroundColor={COLOR_SCREEN_BTN}
                onPress={gotoKTab}
                size={32}
                iconStyle={{
                  margin: 0,
                  padding: 0
                }}
              />
            </View>
          </View>
        </>
      }

    </View>
  );
}
export default ChatComponent;