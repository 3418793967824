import { Svg } from '@react-three/drei';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { Suspense, useLayoutEffect } from 'react';
import { useRef, useState } from 'react';
import { TextureLoader } from 'three';
import { MTLLoader, OBJLoader } from 'three-stdlib';

import * as THREE from 'three';
// extend({extrudeBufferGeometry});

var svgPath = require("../../../assets/logo.svg");

export function XgameSvg() {
  return (
    <Svg src={svgPath} scale={0.02} position={[-1.5, 0, 6.5]} rotation-x={Math.PI / 2}></Svg>
  );
}


export function Hexagon(props) {
  const mesh = useRef();
  const { color, active } = props;
  // https://docs.pmnd.rs/react-three-fiber/api/hooks
  useFrame((state, delta, xrFrame) => {
    if (active) {
      mesh.current.rotation.z += delta;
    } else {
      mesh.current.rotation.z -= delta;
    }
  });

  return (
    <mesh
      {...props}
      ref={mesh}
      scale={0.65}
    >
      <circleGeometry args={[1.0, 6, 0, Math.PI * 2]} />
      <meshPhongMaterial
        color={color}
      />
    </mesh>
  );
}

export function KTabIcon(props) {
  const mesh = useRef();
  const [active, setActive] = useState(true);
  useFrame((state, delta, xrFrame) => {
    if (active) {
      mesh.current.rotation.z += delta / 5;
    } else {
      mesh.current.rotation.z -= delta / 5;
    }
  });
  return (
    <group
      {...props}
      ref={mesh}
      onClick={(event) => setActive(!active)}
      scale={2.0}
    >
      <Hexagon position={[Math.cos(1 * Math.PI / 6) * 1.25, Math.sin(1 * Math.PI / 6) * 1.25, 1.65]} color={"red"} active={active} />
      <Hexagon position={[Math.cos(5 * Math.PI / 6) * 1.25, Math.sin(5 * Math.PI / 6) * 1.25, 1.65]} color={"blue"} active={active} />
      <Hexagon position={[Math.cos(9 * Math.PI / 6) * 1.25, Math.sin(9 * Math.PI / 6) * 1.25, 1.65]} color={"yellow"} active={active} />
    </group>
  );
}

export default function KTabItems() {
  return (
    <Canvas>
      <ambientLight></ambientLight>
      <pointLight position={[10, 10, 10]}></pointLight>
      <Svg src={svgPath} scale={0.01} color={"orange"} position={[-1, 1, 0]}></Svg>
    </Canvas>
  );
}