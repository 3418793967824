import { doc, getDoc, getFirestore, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { getApp } from 'firebase/app';

export const getAccountName = (user) => {
    if (user == null) {
        return '';
    }
    if (user?.email) {
        return user.email;
    }
    if (user?.phoneNumber) {
        let account = user.phoneNumber;
        if (account.charAt(0) == '+') {
            account = account.substring(1);
        }
        return account;
    }
    return user.uid;
}

export const genInitUser = () =>{
    return { _id: '', name: '', avata: '' };
}

export const genUserChat = (user) => {
    let rez = { _id: '', name: '', avata: '' };
    if (user == null) {
        return rez;
    }
    rez._id = user.uid;
    if (user?.email) {
        rez.name = user?.email;
        rez.avata = user?.photoURL;
        return rez;
    }
    if (user?.phoneNumber) {
        let account = user.phoneNumber;
        if (account.charAt(0) == '+') {
            account = account.substring(1);
        }
        rez.name = account;
        rez.avata = require('../../assets/logo.png');
        return rez;
    }
    return rez;
}

export const generateRandomString = (length = 32) => {
    const char = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890';
    const random = Array.from(
        { length: length },
        () => char[Math.floor(Math.random() * char.length)]
    );
    return random.join("");
}

var colors = ["#FF0000", "#00FF00", "#0000FF", "#800000", "#FFA500"];
export const getColorFromUri = (uri) => {
    let hash = colors.length - 1;
    if (uri.length == 0) return colors[hash];
    for (let i = 0; i < uri.length; i++) {
        let char = uri.charCodeAt(i);
        hash = (hash * 31 + char) % colors.length;
    }
    hash = (hash % colors.length + colors.length) % colors.length;
    return colors[hash];
}

export function random(bound) {
    return Math.floor(Math.random() * (bound + 0.5));
}
export function getRandomFuture(bound) {
    let min_value = 0;
    let max_value = bound - 1;
    while (min_value < max_value) {
        let mid_value =Math.floor((min_value + max_value) / 2);
        mid_value = Math.floor((mid_value + max_value + 1) / 2);
        if (random(3) == 0) {
            max_value = mid_value - 1;
        } else {
            min_value = mid_value;
        }
    }
    return min_value;
}

export const getUrl4Slide = async (slideId) => {
    let storage = getStorage(getApp());
    let slideRef = ref(storage, BACKGROUNDSLIDES_STORAGE + slideId + DEFAULT_SUFFIX_IMG);
    let downloadUrl = await getDownloadURL(slideRef);
    return downloadUrl;
}

export const getUrlFromStorage = async(storageFile)=>{
    let storage = getStorage(getApp());
    let slideRef = ref(storage, storageFile);
    let downloadUrl = await getDownloadURL(slideRef);
    return downloadUrl;
}

export const getSlideData = async (slideId) => {
    let db = getFirestore(getApp());
    let slideDocSnapshot = await getDoc(doc(db, BACKGROUNDSLIDE_FIRESTORE, String(slideId)));
    if (slideDocSnapshot.exists()) {
        return slideDocSnapshot.data();
    }
    return {};
}

export const genSlideId4User = async (account) => {
    let db = getFirestore(getApp());
    let slideId = "1";
    let activeGroup = "0";
    let userSnapshot = await getDoc(doc(db, USER_FIRESTORE, account));
    let isMaxSlideId = false;
    if (userSnapshot.exists()) {
        let user = userSnapshot.data();
        activeGroup = user.activeGroup;
        isMaxSlideId = true;
        let groupId = user.groupId;
        if (activeGroup == "-1") {
            isMaxSlideId = false;
            if (groupId.length > 0) {
                activeGroup = groupId[random(groupId.length)];
            } else {
                activeGroup = "0";
            }
        }
    }
    if (activeGroup === undefined){
        activeGroup = "0";
    }

    let groupInfoSnapshot = await getDoc(doc(db, GROUPINFOS_FIRESTORE, activeGroup));
    if (!groupInfoSnapshot.exists()) {
        return slideId;
    }
    let groupInfo = groupInfoSnapshot.data();
    if (isMaxSlideId == true) {
        slideId = parseInt(groupInfo.maxSlideId);
        return slideId;
    }
    let capacity = groupInfo.capacity?groupInfo.capacity: '0';
    if(capacity == '0'){
        return slideId;
    }
    let removeSlide = groupInfo.removeSlide?groupInfo.removeSlide: '0';
    let numberSlide = groupInfo.numberSlide?groupInfo.numberSlide: '1';
    let slideGroupIndex = parseInt(removeSlide) + getRandomFuture(Math.min(parseInt(numberSlide) - parseInt(removeSlide))) + 1;
    let shortcutSnapshot = await getDoc(doc(db, SHORTCUT_FIRESTORE, activeGroup + '_' + slideGroupIndex));
    if (!shortcutSnapshot.exists()) {
        return slideId;
    }
    let shortcut = shortcutSnapshot.data();
    return shortcut.slideId;
}

export const firestoreRef= async(collectionId, docId)=>{
    // console.log("collectionId: ", collectionId, "docId: ", docId);
    let db = getFirestore(getApp());
    let docRef = doc(db, collectionId, docId);
    return docRef;
}

export const updateRoomVoice = async (room, offer) =>{
    let db = getFirestore(getApp());
    await updateDoc(doc(db,"rooms", room), offer);
    let roomVoiceSnapshot = await getDoc(doc(db,"rooms", room));
    return roomVoiceSnapshot;
}
export const getRoomVoice = async (room) =>{
    let db = getFirestore(getApp());
    let roomVoiceSnapshot = await getDoc(doc(db,"rooms", room));
    return roomVoiceSnapshot;
}

export const genMaxSlide4Active = async (activeGroup) => {
    let db = getFirestore(getApp());
    let slideId = "1";
    let groupInfoSnapshot = await getDoc(doc(db, GROUPINFOS_FIRESTORE, activeGroup));
    if (!groupInfoSnapshot.exists()) {
        return slideId;
    }
    let groupInfo = groupInfoSnapshot.data();
    slideId = parseInt(groupInfo.maxSlideId);
    return slideId;
}

export const genSlide4Active = async (activeGroup) => {
    let db = getFirestore(getApp());
    let slideId = "1";
    let groupInfoSnapshot = await getDoc(doc(db, GROUPINFOS_FIRESTORE, activeGroup));
    if (!groupInfoSnapshot.exists()) {
        return slideId;
    }
    let groupInfo = groupInfoSnapshot.data();
    let capacity = groupInfo.capacity?groupInfo.capacity: '0';
    if(capacity == '0'){
        return slideId;
    }
    let removeSlide = groupInfo.removeSlide?groupInfo.removeSlide: '0';
    let numberSlide = groupInfo.numberSlide?groupInfo.numberSlide: '1';
    let slideGroupIndex = parseInt(removeSlide) + getRandomFuture(Math.min(parseInt(numberSlide) - parseInt(removeSlide))) + 1;
    let shortcutSnapshot = await getDoc(doc(db, SHORTCUT_FIRESTORE, activeGroup + '_' + slideGroupIndex));
    if (!shortcutSnapshot.exists()) {
        return slideId;
    }
    let shortcut = shortcutSnapshot.data();
    return shortcut.slideId;
}

export const sleep = (ms)=>{
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const isTopNavigation = (dimension)=>{
    return (dimension == null || dimension.height > THRESH_NAVIGATION);
}