import { SET_UID, SET_USER, SET_SCREEN} from "./actions";
const initGlobalState = {
    uid: '',
    user: { _id: '', name: '', avata: '',  displayName: '', sex: '', yearOfBirth: '', storageAvatar: ''},
    screen: {name: ''}
};
function firebaseReducers(state = initGlobalState, action) {
    switch (action.type) {
        case SET_UID:
            return { ...state, uid: action.payload };
        case SET_USER:
            return { ...state, user: action.payload };
        case SET_SCREEN:
            return { ...state, screen: action.payload};
        default:
            return state;
    }
};
export default firebaseReducers;