import { NavigationContainer } from '@react-navigation/native';
import SlideScreen from './src/screens/SlideScreen';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { Dimensions, Platform } from 'react-native';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { tabStore } from './src/states/store';
import * as WebBrowser from 'expo-web-browser';
import { initializeApp } from 'firebase/app';
import firebase from 'firebase/compat/app';
import { firebaseConfig } from './config';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { setScreen, setUid, setUser } from './src/states/actions';
import { screens } from './src/utils/ScreenUtils';
import MusicScreen from './src/screens/MusicScreen';
import EcoScreen from './src/screens/EcoScreen';
import { genInitUser, genUserChat } from './src/utils/Tool';
import ChatScreen from './src/screens/ChatScreen';
import FormScreen from './src/screens/FormScreen';
import { listenerChatServer, offListenerChatServer } from './src/utils/SeverFuncs';
import SettingUserScreen from './src/screens/SettingUserScreen';
try {
  //console.log(Platform.OS);
  if (Platform.OS === 'web') {
    firebase.initializeApp(firebaseConfig);
  } else {
    initializeApp(firebaseConfig);
  }
} catch (error) {
  console.log("Initializing error ", error);
}
WebBrowser.maybeCompleteAuthSession();
const Stack = createNativeStackNavigator();

export const UserContext = React.createContext();

const Body = () => {
  const { uid } = useSelector(state => state.firebaseReducers);
  const dispatch = useDispatch();
  const [dimension, setDimension] = useState(Dimensions.get('window'));
  const startScreen = screens.home;
  useEffect(() => {
    const subscription = Dimensions.addEventListener(
      'change',
      ({ window }) => {
        setDimension(window);
      }
    )
    dispatch(setScreen({ name: startScreen, isExpand: false }));
    return () => {
      subscription.remove();
    }
  }, []);
  useEffect(() => {
    onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        if (uid != user.uid) {
          dispatch(setUser(genUserChat(user)));
        }
      } else {
        if (uid != "") {
          dispatch(setUser(genInitUser()));
        }
      }
    });
  }, [uid]);

  useEffect(() => {
    listenerChatServer();
    return () => offListenerChatServer();
  }, []);

  return (
    <NavigationContainer>
      <Stack.Navigator
        screenOptions={{
          headerShown: false
        }}
        initialRouteName={startScreen}
      >
        <Stack.Screen
          name={screens.home}
          component={SlideScreen}
          options={{ title: "Home" }}
          initialParams={{ "dimension": dimension }}
        />
        <Stack.Screen
          name={screens.music}
          component={MusicScreen}
          options={{ title: "Music" }}
          initialParams={{ "dimension": dimension }}
        />
        <Stack.Screen
          name={screens.eco}
          component={EcoScreen}
          options={{ title: "Eco" }}
          initialParams={{ "dimension": dimension }}
        />
        <Stack.Screen
          name={screens.chat}
          component={ChatScreen}
          options={{ title: "Chat" }}
          initialParams={{ "dimension": dimension }}
        />
        <Stack.Screen
          name={screens.form}
          component={FormScreen}
          options={{ title: "Form" }}
          initialParams={{ "dimension": dimension }}
        />
        <Stack.Screen name={screens.settingUser} component={SettingUserScreen} initialParams={{ "dimension": dimension }}></Stack.Screen>
      </Stack.Navigator>
    </NavigationContainer>
  )
}

export default function App() {
  return (
    <Provider store={tabStore}>
      <Body />
    </Provider>
  );
}

