import { PixelRatio, Text, TextInput } from "react-native";
import { View } from "react-native";
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useEffect, useState } from "react";
import { Image } from "react-native";
import { TouchableOpacity } from "react-native";
import * as ImagePicker from 'expo-image-picker';
import { getAuth, signOut } from "firebase/auth";
import { SaveFormat, manipulateAsync } from "expo-image-manipulator";
import { SelectList } from "react-native-dropdown-select-list";
import { getDatabase, ref as refRT, onValue, child, off, set } from "firebase/database";

import { COLOR_BACKGROUND_TEXTINPUT, COLOR_BORDER_TEXTINPUT, COLOR_SCREEN_BTN, COLOR_TEXTINPUT, ICON_NAME_DONATE, ICON_NAME_IMAGE_PLUS, ICON_NAME_LOGOUT, TXT_BUILDING, TXT_RESIGN, TXT_ROOM_HIRE, TXT_ROOM_HOMESTAY, TXT_ROOM_SALE, TXT_UPDATE, URI_LOGO } from "../utils/Constants";
import { getDefaultRungCoBuildingSelected, getOptionForBuilding, getRungCoBuildings, rungcoBuildingSave } from "../data/manualData";
import RadioButton from "../guis/RadioButton";
import { callSettingUserDimension } from "../utils/EcoUtils";
import YesNoComponent from "./YesNoComponent";
import { isCorrectPhone } from "../utils/CustomerUtils";
import { getUrlFromStorage } from "../utils/Tool";

const DEFAULT_IMAGE_SIZE = 512;
const formTitle = "ĐĂNG KÝ THÔNG TIN";

const extractPhoneNumber = (phone) => {
  if (phone == undefined || phone == '') return "";
  if (phone.startsWith("84")) {
    return "0" + phone.substring(2);
  }
  return "";
}

const FormComponent = ({ dimension, setShowQR, user, onPressSubmit }) => {
  const [phone, setPhone] = useState(extractPhoneNumber(user.name));
  const [name, setName] = useState(user.displayName);
  const [room, setRoom] = useState("");
  const [roomType, setRoomType] = useState(TXT_ROOM_HOMESTAY);
  const [note, setNote] = useState("");
  const [uriImage, setUriImage] = useState("");
  const [error, setError] = useState("");
  const [building, setBuilding] = useState(getDefaultRungCoBuildingSelected());
  const [editName, setEditName] = useState(false);
  const [editPhone, setEditPhone] = useState((user.name.startsWith("84") == false));
  const [txtSubmit, setTxtSubmit] = useState(TXT_RESIGN);

  const buildings = getRungCoBuildings();
  const [showYesNo, setShowYesNo] = useState(false);
  const { settingWidth, settingHeight } = callSettingUserDimension(dimension);

  var logoSize = settingWidth / 3;
  var textInputHeight = 20;
  var submitBtnWidth = settingWidth / 2;

  useEffect(() => {
    const updateFromLastDonate = async () => {
      onValue(
        child(refRT(getDatabase()), "eco/" + user._id + "/donate"),
        (snapshot) => {
          if (snapshot.exists()) {
            setTxtSubmit(TXT_UPDATE);
            let donate = snapshot.val();
            //console.log(JSON.stringify(donate));
            if (donate?.name) {
              setName(donate.name);
              setEditName(true);
            }
            if (donate?.phone) {
              setPhone(donate.phone);
              setEditPhone(true);
            }
            if (donate?.room) {
              setRoom(donate.room);
            }
            if (donate?.type) {
              setRoomType(donate.type);
            }
            if (donate?.note) {
              setNote(donate.note);
            }
            if(donate?.building){
              setBuilding(donate.building);
            }
            if (donate?.storagePath) {
              getUrlFromStorage(donate?.storagePath).then((url) => {
                setUriImage(url);
              })
            }
          }
        }
      );
    }
    updateFromLastDonate();
    return () => {
      off(child(refRT(getDatabase()), "eco/" + user._id + "/donate"));
    }
  }, [user]);

  const isValidForm = (form) => {
    if (form['phone'] == '' || isCorrectPhone(form['phone']) == false) {
      setError("Lỗi Số Điện Thoại");
      return false;
    }
    if (form['building'] == '') {
      setError("Chưa chọn tòa nhà");
      return false;
    }
    if (form['room'] == '') {
      setError("Chưa có thông tin phòng");
      return false;
    }
    if (form['type'] == '') {
      setError("Chưa chọn mục đích sử dụng phòng");
      return false;
    }
    return true;
  }

  const handleSubmitForm = () => {
    let submitForm = {};
    submitForm['phone'] = phone;
    submitForm['name'] = name;
    submitForm['building'] = building;
    submitForm['room'] = room;
    submitForm['type'] = roomType;
    submitForm['note'] = note;
    submitForm['uri_image'] = uriImage;
    if (uriImage.startsWith("data:") == false) {
      submitForm['uri_image'] = '';
    }
    if (isValidForm(submitForm)) {
      //console.log("submit form: " + JSON.stringify(submitForm));
      setError("");
      if (onPressSubmit) {
        onPressSubmit(submitForm);
      }
    }
  }

  const uploadDonateImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      // aspect: [4, 3], // aspect doesn't work on IOS because its always square.
      quality: 1,
    });
    if (!result.canceled) {
      let imageData = result.assets[0];
      let imageWidth = imageData.width;
      let imageHeight = imageData.height;
      let scale = Math.min(DEFAULT_IMAGE_SIZE / imageWidth, DEFAULT_IMAGE_SIZE / imageHeight);
      let newImageWidth = PixelRatio.roundToNearestPixel(imageWidth * scale);
      let newImageHeight = PixelRatio.roundToNearestPixel(imageHeight * scale);
      const manipResult = await manipulateAsync(imageData.uri, [{ resize: { width: newImageWidth, height: newImageHeight } }], { format: SaveFormat.PNG });
      setUriImage(manipResult.uri);
    }
  }

  let opacityFormView = uriImage == "" ? 1.0 : 0.75;
  const logOut = () => {
    signOut(getAuth());
  }

  return (
    <View style={{ width: dimension.width, height: dimension.height, alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
      {showYesNo == false ?
        <View style={{ width: settingWidth, height: settingHeight, alignItems: "center", opacity: opacityFormView }}>
          {uriImage != "" ?
            <View style={{ width: settingWidth, height: settingHeight, position: "absolute", top: 0, left: 0 }}>
              <Image
                style={{ width: "100%", height: "100%", resizeMode: "stretch", alignItems: "center", justifyContent: "center" }}
                source={{ uri: uriImage }}
              >
              </Image>
            </View>
            :
            <></>
          }
          <Image
            style={{ width: logoSize, height: logoSize, resizeMode: "contain", alignItems: "center", justifyContent: "center" }}
            source={{ uri: URI_LOGO }}
          >
          </Image>
          <View style={{ width: settingWidth, alignItems: "center" }}>
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
              {formTitle}
            </Text>
          </View>
          <View style={{ width: settingWidth, alignItems: "center" }}>
            <Text style={{ fontSize: 13, color: "red" }}>
              {error}
            </Text>
          </View>
          <View style={{ width: settingWidth, margin: 5, flexDirection: "column", alignItems: "center" }}>
            <TextInput
              style={{
                color: "#525252",
                textAlign: "center",
                fontStyle: "italic",
                borderRadius: 80,
                height: 40,
                width: 250,
                margin: 10,
                borderWidth: 2,
                padding: 10,
                backgroundColor: "#A7D1F6",
                borderColor: "#4C8EF6"
              }}
              onChangeText={setName}
              value={name}
              placeholder={"Họ và tên chủ hộ"}
              keyboardType={"numeric"}
              editable={editName}
            />
            <TextInput
              style={{
                textAlign: "center",
                fontStyle: "italic",
                borderRadius: 80,
                height: 40,
                width: 250,
                margin: 10,
                borderWidth: 2,
                padding: 10,
                color: COLOR_TEXTINPUT,
                backgroundColor: COLOR_BACKGROUND_TEXTINPUT,
                borderColor: COLOR_BORDER_TEXTINPUT
              }}
              onChangeText={setPhone}
              value={phone}
              placeholder={"Số điện thoại"}
              keyboardType={"numeric"}
              editable={editPhone}
            />
          </View>
          <View style={{ width: settingWidth, margin: 5, flexDirection: "row", alignItems: "center", justifyContent: "center", height: 50, zIndex: 10 }}>
            <View style={{ position: "absolute", left: settingWidth / 8, top: 0, zIndex: 10 }}>
              {/* <SelectList
                inputStyles={{
                  color: "#525252",
                  fontStyle: "italic",
                  textAlignVertical: "center",
                  width: 65
                }}
                boxStyles={{
                  zIndex: 10,
                  borderRadius: 80,
                  height: 40,
                  width: 115,
                  margin: 10,
                  borderWidth: 2,
                  padding: 10,
                  backgroundColor: "#A7D1F6",
                  borderColor: "#4C8EF6",
                }}
                dropdownStyles={{
                  backgroundColor: "#A7D1F6"
                }}
                setSelected={setBuilding}
                data={buildings}
                placeholder={TXT_BUILDING}
                search={true}
                save={rungcoBuildingSave}
              ></SelectList> */}
              <TextInput
                style={{
                  textAlign: "center",
                  fontStyle: "italic",
                  borderRadius: 80,
                  height: 40,
                  width: 115,
                  margin: 10,
                  borderWidth: 2,
                  padding: 10,
                  color: COLOR_TEXTINPUT,
                  backgroundColor: COLOR_BACKGROUND_TEXTINPUT,
                  borderColor: COLOR_BORDER_TEXTINPUT
                }}
                onChangeText={setBuilding}
                value={building}
                placeholder={TXT_BUILDING}
              />
            </View>
            <View style={{ position: "absolute", right: settingWidth / 8, top: 0 }}>
              <TextInput
                style={{
                  textAlign: "center",
                  fontStyle: "italic",
                  borderRadius: 80,
                  height: 40,
                  width: 115,
                  margin: 10,
                  borderWidth: 2,
                  padding: 10,
                  color: COLOR_TEXTINPUT,
                  backgroundColor: COLOR_BACKGROUND_TEXTINPUT,
                  borderColor: COLOR_BORDER_TEXTINPUT
                }}
                onChangeText={setRoom}
                value={room}
                placeholder={"Số phòng"}
                keyboardType={"numeric"}
              />
            </View>
          </View>

          <View style={{ width: settingWidth, margin: 5, justifyContent: "center", alignItems: "center" }}>
            <RadioButton
              size={textInputHeight}
              description={TXT_ROOM_HOMESTAY.toUpperCase()}
              width={settingWidth / 3}
              height={textInputHeight}
              value={TXT_ROOM_HOMESTAY}
              choosed={roomType}
              onPress={() => setRoomType(TXT_ROOM_HOMESTAY)}
            />
          </View>
          <View style={{ width: settingWidth, margin: 5 }}>

            <View style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "center"
            }}>
              <RadioButton
                size={textInputHeight}
                description={TXT_ROOM_SALE.toUpperCase()}
                width={settingWidth / 3}
                height={textInputHeight}
                value={TXT_ROOM_SALE}
                choosed={roomType}
                onPress={() => setRoomType(TXT_ROOM_SALE)}
              />
              <RadioButton
                size={textInputHeight}
                description={TXT_ROOM_HIRE.toUpperCase()}
                width={settingWidth / 3}
                height={textInputHeight}
                value={TXT_ROOM_HIRE}
                choosed={roomType}
                onPress={() => setRoomType(TXT_ROOM_HIRE)}
              />
            </View>
          </View>
          <View style={{ width: settingWidth, alignItems: "center" }}>
            <Icon.Button
              name={ICON_NAME_IMAGE_PLUS}
              size={24}
              borderRadius={24}
              onPress={uploadDonateImage}
              iconStyle={{
                padding: 0,
                margin: 0
              }}
            ></Icon.Button>
          </View>
          <View style={{ width: 2 * settingWidth / 3, height: "15%", margin: 5 }}>
            <TextInput
              style={{
                borderRadius: 10,
                height: "100%",
                backgroundColor: "#A7D1F6",
              }}
              placeholder={"Ghi chú..."}
              multiline={true}
              value={note}
              onChangeText={setNote}
            ></TextInput>
          </View>
          <View style={{ width: submitBtnWidth, alignItems: "center", margin: 10 }}>
            <TouchableOpacity
              style={{ alignItems: "center", justifyContent: "center", backgroundColor: "#538EF6", padding: 10, width: "80%", borderRadius: 80 }}
              onPress={handleSubmitForm}
            >
              <Text>{txtSubmit}</Text>
            </TouchableOpacity>
          </View>
        </View>
        :
        <YesNoComponent dimension={dimension} onPressYes={logOut} onPressNo={() => setShowYesNo(false)} />
      }
      <View style={{ width: 50, height: 50, bottom: 50, right: 50, position: "absolute" }}>
        <Icon.Button
          name={ICON_NAME_DONATE}
          size={35}
          borderRadius={50}
          onPress={() => setShowQR(true)}
        ></Icon.Button>
      </View>
      <View style={{ flexDirection: "row-reverse", position: "absolute", top: 5, right: 5, zIndex: 2, alignItems: "flex-start" }}>
        <View style={{ marginVertical: 5, marginHorizontal: 5 }}>
          <Icon.Button
            name={ICON_NAME_LOGOUT}
            backgroundColor={COLOR_SCREEN_BTN}
            onPress={() => setShowYesNo(true)}
            size={32}
            borderRadius={32}
            iconStyle={{
              margin: 0,
              padding: 0
            }}
          />
        </View>
      </View>
    </View >
  );
}
export default FormComponent;